import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rh-confirm-modal',
  templateUrl: 'rh-confirm-modal.component.html',
  styleUrls: ['rh-confirm-modal.component.scss']
})

export class RhConfirmModalComponent {
	@Input() config: { title: string; content: string };
	@Input() isLoading: false;
	@Output() choose: EventEmitter<boolean> = new EventEmitter();

	onChoose(option: boolean): void {
		this.choose.emit(option);
	}
}