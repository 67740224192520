<div class="col spb p-sm bg-white shadow w-280 fixed modal" [ngClass]="{'height-42vh': isLoading, 'h-248' : !isLoading }">
		<rh-spinner *ngIf="isLoading"></rh-spinner>
	<div class="col p_t-lg p_r-lg p_l-lg">
		<h1 class="no-margin c-secondary">{{ config.title }}</h1>
		<p class="m_t-xl m_b-md c-dark font-400">{{ config.content }}</p>
	</div>
	<div class="row h-e">
		<button type="button" class="row abs m_r-sm btn-modal c-secondary" (click)="onChoose(false)">CANCEL</button>
		<button type="button" class="row abs btn-modal c-secondary" (click)="onChoose(true)">YES</button>
	</div>
</div>

<div class="fixed pos_t-60 pos_l-0 nav-bg opacity-1"></div>