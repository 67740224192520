import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface IQuantityChangeEvent {
  action: string;
  quantity: number;
}

@Component({
  selector: 'rh-quantity',
  templateUrl: './rh-quantity.component.html',
  styleUrls: ['./rh-quantity.component.scss']
})

export class RhQuantityComponent {
  @Input() maxQuantity = null;
  @Input() label = 'Quantity';
  @Input() quantity = 0;
  @Input() disabled = false;

  @Output() quantityChanged: EventEmitter<IQuantityChangeEvent> = new EventEmitter<IQuantityChangeEvent>();

  increaseQuantity() {
    if (this.maxQuantity && this.quantity === this.maxQuantity) { return; }
    this.quantity++;
    this.quantityChanged.emit({ action: 'increase', quantity: this.quantity });
  }

  decreaseQuantity() {
    if (this.quantity === 0) { return; }
    this.quantity--;
    this.quantityChanged.emit({ action: 'decrease', quantity: this.quantity});
  }
}

