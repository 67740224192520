import { FileData } from '../../component-library/rh-media-manger/rh-media-manger.component';
import { IEvent } from './common.interface';
import { IRoom } from './room.interface';
import { IMeterReadingsEvent } from "./meter-reading.interface";
import { ISafety } from "./safety.interface";
import { Security } from "./security.interface";
import { Documents } from "./documentation.interface";
import { AdditionalInfo } from "./additional-info.interface";
import { IProperty } from './rh-property.interface';
import { IInspectionSummary } from './summary.interface';
import { ITerms } from './terms.interface';

export const COMPLETED_INSPECTION = 'completed-inspection';
export const INSPECTION_ID_PARAM = 'inspectionId';
export const REPORT = 'inspection-report';

export enum CompletedInspectionMode {
    Current = 'current',
    Previous = 'previous'
}

export interface InspectedRooms {
    inspectedRooms?: Array<{
        [key: string]: {
            [key in CompletedInspectionMode]: IRoom;
        }
    }>;
}
export interface ICommonInspection extends InspectedRooms {
    id: string;
    performed_at_time: string;
    performed_at_date: string;
    is_completed: boolean;
    is_approved: boolean;
    is_shared: boolean;
    approval_required: boolean;
    has_previous: boolean;
    event: IEvent;
    previous_inspection: any;
    property?: IProperty;
    inventory?: IRoom[];
    reading: IMeterReadingsEvent;
    safety: ISafety;
    security: Security;
    documents: Documents;
    document?: string;
    additionalInformation: AdditionalInfo;
    inspectionSummary: IInspectionSummary;
    termAndCondition: ITerms;
    approvalStatus: any;
}


export interface ICurrentInspection {
    success?: boolean;
    property_id: string;
    inspection: ICommonInspection;
}

export interface IInspectionEvent {
    id: string;
    performed_at_time: string;
    performed_at_date: string;
    is_completed?: boolean,
    is_approved?: boolean,
    is_shared?: boolean,
    event: {
        id: string;
        name: string;
    };
    property: {
        id: string,
        address: string;
        houseName: string;
    };
}

export interface IInspection {
    inspection_id?: string;
    is_approver?: boolean,
    is_external_user?: boolean,
    property_id: string;
    event?: IEvent;
    inspection: ICommonInspection
}

export interface IInspectionApprovalStatusResponse {
    approvalStatus: IInspectionApprovalStatus;
}

export interface IInspectionApprovalStatus{
    approvers?: IInspectionStatus[],
    viewers?: IInspectionStatus[]
}

export interface IInspectionStatus {
    email: string,
    user_type?: string,
    status?: string,
    approved_at?: string,
}
