import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { RhSearchListModalComponent } from './search-list-modal/search-list-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { RhNotificationModalComponent } from './notification-modal/notification-modal';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    PipesModule
  ],
  declarations: [
    RhSearchListModalComponent,
    RhNotificationModalComponent
  ],
  exports: [
    RhSearchListModalComponent,
    RhNotificationModalComponent
  ]
})

export class ModalsModule { }
