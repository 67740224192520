import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    IInspection,
    COMPLETED_INSPECTION,
    REPORT
} from '../interfaces/inspection.interface';

import { IReport } from '../interfaces/report.interface';
import * as fromReport from 'src/app/store/selectors/report/report.selector';
import { IAppState } from '../store/state/app.state';
import { Store } from '@ngrx/store';

const INVENTORY_URL = `${environment.url}/inventory`;

// Response Interfaces
interface IInspectionReportsResponse { success: boolean; property_id: string; report: IReport[]; }
interface IInspectionReportResponse { success: boolean; property_id: string; report: IReport; }

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService {

    public report(): Observable<IReport> {
        return this.store.select(fromReport.selectReportById);
    }

    constructor(
        protected httpClient: HttpClient,
        private store: Store<IAppState>
        ) {
        super(httpClient);
    }

    public shareReport(propertyId: string, reportId: string, data: { emails: any }): Observable<any> {
        return this.httpClient.post<any>(`${INVENTORY_URL}/${propertyId}/report/${reportId}/share`, data);
    }

    public fetchAllReports(propertyId: string): Observable<IReport[]> {
        return this.httpClient.get<IInspectionReportsResponse>(`${INVENTORY_URL}/${propertyId}/reports`).pipe(
            map(res => res.report || [])
        );
    }

    public fetchReport(propertyId: string, reportId: string): Observable<IReport> {
            return this.httpClient.get<IInspectionReportResponse>(`${INVENTORY_URL}/${propertyId}/report/${reportId}`).pipe(
                map(res => res.report)
            );
    }

    public verifySharedReport(propertyId: string, reportId: string, data: { code: string, token: string }): Observable<any> {
        return this.httpClient.post<IInspection>(`${INVENTORY_URL}/${propertyId}/report/${reportId}/share/verification`, data);
    }
    
    public createReport(propertyId: string, data): Observable<IReport> {
        return this.httpClient.post<IInspectionReportResponse>(`${INVENTORY_URL}/${propertyId}/report`, data).pipe(
            map(res => res.report)
        );
    }

    public toOpen(propertyId: string, reportId: string, data: { state: boolean }): Observable<any> {
        return this.httpClient.post<IInspection>(`${INVENTORY_URL}/${propertyId}/report/${reportId}/open`, data);
    }

}
