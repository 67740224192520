<div
  class="row v-c spb full-width h-65 p_l-md p_r-md bg-white b_bottom-light b_top-light"
>
  <div class="row">
    <p class="c-dark">{{ label }}</p>
  </div>
  <div class="row spb v-c">
    <button
      type="button"
      class="row abs m_r-md btn-square c-shade b-shade hover-shade"
      [disabled]="quantity === 0 || disabled"
      (click)="decreaseQuantity()"
    >
      -
    </button>
    <strong
      class="counter font-500 c-dark"
	  [ngClass]="{'c-secondary' : quantity > 0}"
      >{{ quantity }}</strong
    >
    <button
      type="button"
      class="row abs m_l-md btn-square c-shade b-shade hover-shade"
      [disabled]="(maxQuantity && quantity === maxQuantity) || disabled"
      (click)="increaseQuantity()"
    >
      +
    </button>
  </div>
</div>
