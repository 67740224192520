import { Subscription } from 'rxjs';
import { Component, Input, ViewChild, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'rh-date-picker',
  templateUrl: 'rh-date-picker.component.html',
  styleUrls: ['rh-date-picker.component.scss']
})

export class RhDatepickerComponent implements OnInit, OnDestroy {

  @Input() public dateControl: FormControl;
  @Input() public placeholder = 'Select Date';
  @Input() public format = 'yyyy-MM-dd';
  @Input() public disableClick = false;
  @Input() public set minDate(date: Date) {
    this._minDate = formatDate(date, 'yyyy-MM-dd', 'en_US');
  }
  @Input() public set maxDate(date: Date) {
    this._maxDate = formatDate(date, 'yyyy-MM-dd', 'en_US');
  }

  @ViewChild('datepicker', { read: ElementRef, static: true }) public datePicker: ElementRef;

  public control: FormControl;

  // tslint:disable-next-line: variable-name
  public _maxDate: string;
  // tslint:disable-next-line: variable-name
  public _minDate: string;

  private subscription: Subscription;

  public ngOnInit(): void {
    this.control = new FormControl();
    this.subscription = this.control.valueChanges.subscribe(value => {
      const date = formatDate(value, this.format, 'en_US');
      this.dateControl.setValue(date);
      this.dateControl.markAsDirty();
    });
  }

  @HostListener('click', ['$event'])
  onClick(event?) {
    if (!this.disableClick) {
      this.datePicker.nativeElement.click();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
